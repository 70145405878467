import { observable, makeAutoObservable, action, computed } from "mobx";

class OverlayStoreModel {
  @observable count = 44;
  @observable menuOpen = false;
  @observable logoLoaded = false;
  @observable sliderBoxLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action increment = () => {
    this.count++;
  }

  @action setLogoLoaded = () => {
    this.logoLoaded = true;
  }

  @action setSliderBoxLoaded = () => {
    this.sliderBoxLoaded = true;
  }

  @computed get menuState() {
    return this.menuOpen;
  }

  @action decrement = () => {
    this.count--;
  }

  @action toggleMenu = () => {
    this.menuOpen = !this.menuOpen;
  }

  @action closeMenu = () => {
    this.menuOpen = false;
  }
}


const OverlayStore = new OverlayStoreModel()

export default OverlayStore;
